import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_FOLLOW_FUTURE } from '../../../../constants/search/scope';
import { useInitializeActiveShorcut } from '../../../../hooks/myartprice/useInitializeActiveShorcut';
import { usePaginatedSearch } from '../../../../hooks/myartprice/usePaginatedSearch';
import { get as getUiDictionary } from '../../../../redux/selectors/ui/dictionary';
import { updateSearchScope } from '../../../../redux/actions/myartprice/data';
import { useBreadcrumb } from '../../../../hooks/myartprice/useBreadcrumb';
import { getItemsPerpage } from '../../../../redux/selectors/preferences';
import { isInViewport, scrollToElement } from '../../../../utils/ui';
import { getCurrentQueryObject } from '../../../../utils/http';
import BackToTopButton from '../../../common/BackToTopButton';
import Pagination from '../../../common/Pagination';
import VerticalBar from '../../common/VerticalBar';
import MainLayout from '../../common/MainLayout';
import '../../common/styles/myartpriceBar.scss';
import NoResults from '../../common/NoResults';
import '../../common/styles/myartprice.scss';
import SearchBar from './SearchBar';
import Lot from './Lot';
import './stylesheet.scss';

const Lots = ({ searchScope }) => {
  const dispatch = useDispatch();
  const refTitle = useRef();
  const perPage = useSelector(getItemsPerpage);
  const returnToIdlot = useSelector(state => getUiDictionary(state, { id: 'returnToIdlot' }), shallowEqual);
  const { advancedParams, fetch, ids, entities, totalCount, loading, page } = usePaginatedSearch({ scope: searchScope });

  useInitializeActiveShorcut();
  useBreadcrumb();

  useEffect(() => {
    dispatch(updateSearchScope(searchScope));
    fetch(getCurrentQueryObject());
  }, [searchScope]);

  useEffect(() => {
    if (returnToIdlot && !loading) {
      const tile = document.querySelector(`#lot-${returnToIdlot}`);
      if (tile) {
        window.scrollTo(0, tile.offsetTop);
      }
    }
  }, [loading]);

  const handlePaginationClick = p => {
    fetch({ ...advancedParams, p });

    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  return (
    <MainLayout ref={refTitle}>
      <VerticalBar />
      <div className="col-container">
        <SearchBar baseParams={{}} ids={ids || []} newSelectionKey="myartprice" fetch={fetch} searchScope={searchScope} />

        {loading && (
          <div className="spinner">
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        )}

        {!loading && (
          <>
            <div className="data-container">
              {ids.map(idlot => (
                <Lot id={idlot} key={`lot-${idlot}`} crypto={get(entities, `${idlot}.crypto`)} />
              ))}

              {ids.length === 0 && (
                <>
                  {searchScope === MYARTPRICE_LOTS_AUCTIONED_OFF && <NoResults messageRsxKey="component.myartprice.lot.m3" linkRsxKey="component.myartprice.lot.m4" href="/search" />}
                  {(searchScope === MYARTPRICE_LOTS_FUTURE_NEW || searchScope === MYARTPRICE_LOTS_FUTURE_ALL) && (
                    <NoResults messageRsxKey="component.myartprice.lot.m5" linkRsxKey="component.myartprice.lot.m6" href="/sales/futures" />
                  )}
                  {searchScope === MYARTPRICE_LOTS_FOLLOW_PAST && <NoResults messageRsxKey="component.myartprice.lot.m7" linkRsxKey="component.myartprice.lot.m4" href="/search" />}
                  {searchScope === MYARTPRICE_LOTS_FOLLOW_FUTURE && <NoResults messageRsxKey="component.myartprice.lot.m8" linkRsxKey="component.myartprice.lot.m4" href="/sales/futures" />}
                </>
              )}
            </div>

            {totalCount / perPage > 1 && <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />}

            <div
              style={{
                position: 'sticky',
                bottom: '25px',
                float: 'right',
                transform: 'translateX(10px)',
              }}
            >
              <BackToTopButton top={refTitle.current} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Lots;
