import React, { useState, useRef, useEffect, useCallback } from 'react';
import { isFunction, isEqual, omit, pick, isPlainObject, isEmpty, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSearch } from '../../../hooks/useSearch';
import Drawer from './Drawer';
import QuickFilters from './QuickFilters';
import HorizontalBar from './HorizontalBar';
import { EMPTY_OBJECT } from '../../../constants/utils';
import { updateUrlQuery } from '../../../utils/http';
import { aapiBeacon } from '../../../services/analytics/aapi';

import './stylesheet.scss';

const IGNORED_PARAMS = ['p', 'ipp', 'nbd', 'sort']; // These params will not trigger any change in the search module
function withoutIgnoredParams(params) {
  if (!isPlainObject(params)) {
    return undefined;
  }
  return omit(params, IGNORED_PARAMS);
}
function extractIgnoredParams(params) {
  return pick(params, IGNORED_PARAMS);
}

// Used to remove baseParams from advancedParams to prevent unwanted effects
function withoutKeys(params, keys = []) {
  if (!isPlainObject(params)) {
    return undefined;
  }
  return omit(params, keys);
}

// remove undefined field (to improve equality detection between render)
// also removes ignored params
function cleanParams(params) {
  const cleaned = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && !IGNORED_PARAMS.includes(key)) {
      if (isPlainObject(value)) {
        cleaned[key] = cleanParams(value);
      } else {
        cleaned[key] = value;
      }
    }
  });
  if (isEmpty(cleaned)) {
    return EMPTY_OBJECT;
  }
  return cleaned;
}

const Search = ({ searchScope, baseParams = EMPTY_OBJECT, advancedParams: givenAdvancedParams = undefined, drawer, quickFilters, onChange = noop, horizontalBar, children, autoUpdateUrl = false }) => {
  const drawerId = useRef(`SEARCH-DRAWER-ID-${uuidv4()}`);
  const [rawParams, setRawParams] = useState(givenAdvancedParams); // Raw Params (not filtered to see what's interesting for the search) // lets us correctly manage the query
  const [advancedParams, setAdvancedParams] = useState(() => withoutIgnoredParams(withoutKeys(givenAdvancedParams, Object.keys(baseParams)))); // Advanced params (set from user, or from the drawer)
  const [finalParams, setFinalParams] = useState(() => cleanParams({ ...baseParams, ...advancedParams })); // Final params (Base params + advanced params + quick params)

  useSearch({ searchScope, baseParams, advancedParams });

  // the return value of handleChange indicates if we must pursue the processing, we get this value from the return value of onChange
  // return false from onChange to stop the processing
  const handleChange = useCallback(
    (params, from) => {
      const newParams = cleanParams({ ...baseParams, ...params });
      // comparing finalParams to see if we have to update
      if (!isEqual(finalParams, newParams)) {
        const cleanedParams = cleanParams(params); // without baseParams

        // aapi (analytics)
        aapiBeacon('search', { searchscope: searchScope, base_keys: Object.keys(baseParams), advanced_keys: Object.keys(cleanedParams) });

        // no need to continue if we are not in an SPA (return false from the onChange)
        // Returns params: complete list of all params, including baseParams; searchParams: cleaned Advanced params; ignoredParams: ignored params (p, ipp, etc)
        const canContinue = onChange?.({ params: newParams, searchParams: cleanedParams, ignoredParams: extractIgnoredParams(rawParams), from });
        if (canContinue !== false) {
          setFinalParams(newParams);
        }
        return canContinue;
      }
      return false;
    },
    [searchScope, finalParams, setFinalParams],
  );

  // update rawParams when givenAdvancedParams change
  useEffect(() => {
    if (!isEqual(rawParams, givenAdvancedParams)) {
      setRawParams(givenAdvancedParams);
    }
  }, [givenAdvancedParams, rawParams, setRawParams]);

  // update search based on rawParams
  useEffect(() => {
    const processedParams = withoutIgnoredParams(withoutKeys(rawParams, Object.keys(baseParams)));
    if (handleChange(processedParams, 'rawParams') !== false) {
      setAdvancedParams(givenAdvancedParams);
    }
  }, [setAdvancedParams, rawParams]);

  // autoUpdateurl (when advancedParams or rawParams change)
  useEffect(() => {
    // IDEA: to manage "back" even on quickfilters, we could try to use https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
    if (autoUpdateUrl) {
      updateUrlQuery({
        ...advancedParams,
        ...extractIgnoredParams(rawParams),
      });
    }
  }, [rawParams, advancedParams, autoUpdateUrl]);

  // handle different parts of the search module (will be forwarded to handleChange generic method)
  const handleHorizontalBarChange = values => handleChange({ ...advancedParams, ...values }, 'horizontalBar');
  const handleQuickFiltersChange = values => handleChange({ ...advancedParams, ...values }, 'quickFilters');
  const handleDrawerChange = values => {
    if (handleChange(values, 'drawer') !== false) {
      setAdvancedParams(values);
    }
  };
  const handleUserChange = values => {
    // user changes trigger a rawParams change
    if (!isEqual(rawParams, values)) {
      setRawParams(values);
    }
  };

  // helper methods
  const renderHorizontalBar = () =>
    horizontalBar({
      drawerId: drawerId.current,
      setAdvancedParams: handleUserChange,
      baseParams,
      advancedParams,
      searchParams: cleanParams(advancedParams),
      params: finalParams,
      ignoredParams: extractIgnoredParams(rawParams),
    });

  return (
    <div className="Search">
      {horizontalBar && <HorizontalBar render={renderHorizontalBar} searchScope={searchScope} onChange={handleHorizontalBarChange} />}
      {drawer && <Drawer id={drawerId.current} render={drawer} searchScope={searchScope} onChange={handleDrawerChange} />}
      <div className="Search-Body">
        {quickFilters && <QuickFilters render={quickFilters} searchScope={searchScope} onChange={handleQuickFiltersChange} />}
        {children && (
          <div className="Search-children">
            {isFunction(children) &&
              children({
                drawerId: drawerId.current,
                setAdvancedParams: handleUserChange,
                baseParams,
                advancedParams,
                searchParams: cleanParams(advancedParams),
                params: finalParams,
                ignoredParams: extractIgnoredParams(rawParams),
              })}
            {!isFunction(children) && React.Children.map(children, child => child)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
